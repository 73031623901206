import React from "react";
import { Spacer } from "../components";

const Confirmation = (props) => {
  return (
    <div className="container__form">
      <h3>De data is verzonden!</h3>

      <p className="textBlock">
        Bedankt voor je bijdrage aan het verminderen van voedselverspilling en
        ondervoeding in de zorgsector
      </p>
      <b>Samen maken we het verschil!</b>
      <Spacer />
      <button
        data-testid="backToStart"
        onClick={() => props.backToHome()}
        className="button__primary"
      >
        <i className="fas fa-chevron-left"></i>
        <span>Naar het begin</span>
      </button>
      {/* Not part of MVP to display dashboard in app */}
      {/* <button className="button__primary">
        <span>Dashboard bekijken</span>
        <i className="fas fa-chevron-right"></i>
      </button> */}
    </div>
  );
};

export default Confirmation;
