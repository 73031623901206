import request from "../helpers/request";
import { AuthHeader } from "../helpers";
export { sendMeasurement };

async function sendMeasurement(
  residents,
  department,
  waste_type,
  weight,
  meal_category,
  employee,
  date,
  note
) {
  return request({
    url: "measurements/create",
    method: "POST",
    data: {
      residents_present: residents,
      department_id: department.id,
      waste_type_id: waste_type.id,
      weight: weight,
      meal_category_id: meal_category.id,
      employee: employee,
      date: date,
      note: note
    },
    headers: AuthHeader(),
  }).then((res) => {
    return res;
  });
}
