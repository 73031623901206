import React from "react";
import { Spacer } from "../components";

const WeightMeasurement = (props) => {
  return (
    <div className="container__form">
      <span>Weging in grammen</span>
      <input
        placeholder="Grammen"
        data-testid="weightMeasurement"
        className="input"
        onChange={(e) => props.setWeightMeasurement(e.target.value)}
        type="text"
      />
      {/* <Spacer />
      <span>Datum van meting</span>
      <input
        className="input"
        type="date"
        onChange={(e) => props.setDate(e.target.value)}
        defaultValue={new Date().toISOString().substr(0, 10)}
      /> */}
      <Spacer />
      <span>Wil je een opmerking toevoegen?</span>
      <textarea
        className="textArea"
        onChange={(e) => props.setNote(e.target.value)}
        id=""
        cols="30"
        rows="10"
      ></textarea>
      <Spacer />
      {props.weightMeasurement && (
        <button
          data-testid="weightNextPage"
          className="button"
          onClick={() => props.nextStep()}
        >
          Doorgaan
        </button>
      )}
    </div>
  );
};

export default WeightMeasurement;
