import React from "react";
import { Spacer } from "../components";

const Overview = (props) => {
  const date = new Date(props.date)
  return (
    <div className="container__form">
      <Spacer />
      <span>Overzicht ingevulde gegevens</span>
      <Spacer />
      <hr className="line" />
      <Spacer />
      <span>Afdelingsgegevens</span>
      <span className="input">
        {props.department.name}, {props.employee}
      </span>
      <Spacer />
      <span>Aantal personen</span>
      <span className="input">{props.personCount} personen</span>
      <Spacer />
      <span>Categorie verspilling</span>
      <span className="input">
        {props.mealCategory.name}
      </span>
      <Spacer />
      <span>Reden van derving</span>
      <span className="input">
        {props.wasteType.name}
      </span>
      <Spacer />
      <span>Gewicht en meetdatum</span>
      <span className="input">
        {props.weightMeasurement} gram, {date.toLocaleDateString('nl-NL', { year: 'numeric', month: '2-digit', day: '2-digit' })}
      </span>
      <Spacer />
      <button
        data-testid="submitButton"
        className="button"
        onClick={() => props.nextStep()}
      >
        Verzenden
      </button>
      <Spacer />
      <button
        data-testid="newMeasurementButton"
        onClick={() => props.newMeasurement(2)}
        className="button__primary"
      >
        <i className="fas fa-chevron-left"></i>
        <span>Nieuwe meting</span>
      </button>
      <Spacer />
      <button
        data-testid="newMeasurementButton"
        onClick={() => props.newMeasurement(3)}
        className="button__primary"
      >
        <i className="fas fa-chevron-left"></i>
        <span>Nieuw maaltijdcomponent</span>
      </button>
    </div>
  );
};

export default Overview;
