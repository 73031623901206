import React from "react";

const ModalComponent = (props) => {
  const { toggle, title, children, visible } = props;

  return (
    <div onClick={toggle} style={{ display: visible }} className="overlay">
      <div onClick={(ev) => ev.stopPropagation()} className="overlay__modal">
        <div className="overlay__modal__header">
          <i
            className="overlay__modal__header__close fas fa-times"
            onClick={toggle}
          ></i>
          <span className="overlay__modal__header__title">{title}</span>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ModalComponent;
