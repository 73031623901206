import React from "react";
import { Header } from "../components";
import { FAQ as questions } from "../constants";

const FAQ = (props) => {

  const handleToggle = key => {
    document.querySelector("#answer-"+key).classList.toggle('answer__active')
    document.querySelector("#icon-"+key).classList.toggle('fa-chevron-down')
    document.querySelector("#icon-"+key).classList.toggle('fa-chevron-up')
  }

  const toggleSidebar = () => {
    const sidebar = document.querySelector(".sidebar")
    sidebar.classList.remove('showSidebar')
  }

  return (
    <div style={{height: '100%'}}>
      <Header
        headerText={"Veelgestelde vragen"}
      />
      <div 
        onClick={toggleSidebar}
        style={{
          minHeight: '100%', 
          justifyContent: 'start', 
          alignItems: 'start',
          backgroundColor: '#07948d',
          borderTop: '1px solid white'
        }} 
        className="container__form"
      >
        <ul className="faq">           
          {questions.questions.map((item, i) => {
            return(
              <li className="faq__item" key={i}>
                <div 
                  onClick={() => handleToggle(i)} 
                  className="faq__item__question">
                  {item.q} <i id={"icon-"+i} style={{float: 'right'}} className="fas fa-chevron-down"></i>
                </div>
                <div id={"answer-"+i} className="faq__item__answer">
                  {item.a} 
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FAQ;
