import React from "react";
import { Spacer } from "../components";

const Patients = (props) => {
  return (
    <div className="container__form">
      <span>Datum van meting</span>
      <input
        className="input"
        type="date"
        data-testid="dateInput"
        onChange={(e) => props.setDate(e.target.value)}
        defaultValue={new Date().toISOString().substr(0, 10)}
      />
      <Spacer />
      <span>Hoeveel personen eten er mee?</span>
      <input
        placeholder="aantal personen"
        data-testid="patientsAmount"
        onChange={(e) => props.setPersonCount(e.target.value)}
        className="input"
        type="text"
      />
      {props.personCount && (
        <button
          data-testid="patNextPage"
          onClick={() => props.nextStep()}
          className="button"
        >
          Doorgaan
        </button>
      )}
    </div>
  );
};

export default Patients;
