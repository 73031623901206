import React, { useEffect, useState } from "react";
import { Container, Header } from "../components";
import { sendMeasurement } from "../services";
import {
  Home,
  DepartmentSelect,
  Category,
  Patients,
  WeightMeasurement,
  Overview,
  Confirmation,
} from "./";

const Form = () => {
  useEffect(() => {
    setDate(new Date().toISOString().substr(0, 10));
  }, []);

  // Functions
  const handleSubmit = () => {
    sendMeasurement(
      personCount,
      department,
      wasteType,
      weightMeasurement,
      mealCategory,
      employee,
      date,
      note
    ).then(() => nextStep());
  };

  const previousStep = () => {
    if (formStep > 0) setFormStep(formStep - 1);
  };

  const nextStep = () => {
    if (formStep < 10) setFormStep(formStep + 1);
  };

  const backToHome = () => {
    setFormStep(0);
  };

  const newMeasurement = (step) => {
    sendMeasurement(
      personCount,
      department,
      wasteType,
      weightMeasurement,
      mealCategory,
      employee,
      date,
      note
    ).then(() => {
      setFormStep(step);
    });
  };
  // Form updates
  const headerTextArray = {
    0: "Welkom",
    1: "Afdeling selecteren",
    2: "Aantal personen",
    3: "Categorie verspilling",
    4: "Gegevens weging",
    5: "Overzicht",
    6: "Bevestiging",
  };
  const [formStep, setFormStep] = useState(0);

  // Form values
  const [department, setDepartment] = useState(); // 🔑 Department id
  const [wasteType, setWasteType] = useState(); //  🔑 Reason for waste id
  const [mealCategory, setMealCategory] = useState(); // 🔑  Meal category id
  const [weightMeasurement, setWeightMeasurement] = useState(); // Weight in grams
  const [personCount, setPersonCount] = useState(); // Number of eaters
  const [employee, setEmployee] = useState(); // Employee name
  const [date, setDate] = useState(); // Date of measurement
  const [note, setNote] = useState(''); // Extra note
  // const [employeeEmail, setEmployeeEmail] = useState(); // Optional confirmation email ** not part of MVP

  return (
    <div style={{height: '100%'}}>
      <Header
        formStep={formStep}
        previous={previousStep}
        headerText={headerTextArray[formStep]}
      />
      <Container>
        {formStep === 0 && <Home formStep={formStep} nextStep={nextStep} />}
        {formStep === 1 && (
          <DepartmentSelect
            formStep={formStep}
            nextStep={nextStep}
            setDepartment={setDepartment}
            department={department}
            setEmployee={setEmployee}
            employee={employee}
          />
        )}
        {formStep === 2 && (
          <Patients
            nextStep={nextStep}
            personCount={personCount}
            setDate={setDate}
            date={date}
            setPersonCount={setPersonCount}
          />
        )}
        {formStep === 3 && (
          <Category
            nextStep={nextStep}
            setMealCategory={setMealCategory}
            mealCategory={mealCategory}
            setWasteType={setWasteType}
            wasteType={wasteType}
          />
        )}
        {formStep === 4 && (
          <WeightMeasurement
            // setDate={setDate}
            setWeightMeasurement={setWeightMeasurement}
            setNote={setNote}
            nextStep={nextStep}
            // date={date}
            weightMeasurement={weightMeasurement}
          />
        )}
        {formStep === 5 && (
          <Overview
            department={department}
            employee={employee}
            personCount={personCount}
            mealCategory={mealCategory}
            wasteType={wasteType}
            weightMeasurement={weightMeasurement}
            date={date}
            newMeasurement={newMeasurement}
            nextStep={handleSubmit}
          />
        )}
        {formStep === 6 && <Confirmation backToHome={backToHome} />}
      </Container>
    </div>
  );
};

export default Form;
