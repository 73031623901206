import React, { useState, useEffect } from "react";
import { GridElement, ModalComponent, Spacer } from "../components";
import { getDepartments } from "../services";

const DepartmentSelect = (props) => {
  useEffect(() => {
    loadDepartments();
  }, []);

  async function loadDepartments() {
    let departments = await getDepartments();
    if (departments !== undefined) setDepartments(departments);
  }

  const openModal = (dep) => {
    setModal(!modal);
    props.setDepartment(dep);
  };

  const [modal, setModal] = useState("none");
  const [departments, setDepartments] = useState();

  const toggleModal = () => {
    if (modal === "none") setModal("block");
    else setModal("none");
  };

  return (
    <div className="container__filler">
      {departments && (
        <div className="container__filler">
          <br />
          <span style={{ margin: "10px" }}>Afdeling selecteren</span>
          <br />
          <br />
          <div className="grid">
            {departments.departments.map((dep, i) => {
              return (
                <GridElement
                  title={dep.name}
                  onClick={() => openModal(dep)}
                  key={dep.id}
                />
              );
            })}
          </div>
          {props.department && (
            <ModalComponent
              toggle={toggleModal}
              visible={modal}
              title={props.department.name}
            >
              <span>Naam medewerker</span>
              <input
                className="input"
                data-testid="employeeName"
                onChange={(e) => props.setEmployee(e.target.value)}
                type="text"
                placeholder=""
              />
              <Spacer />
              {props.employee && (
                <button
                  data-testid="depNextPage"
                  onClick={() => props.nextStep()}
                  className="button"
                >
                  Doorgaan
                </button>
              )}
              <Spacer />
            </ModalComponent>
          )}
        </div>
      )}
    </div>
  );
};

export default DepartmentSelect;
