import React from 'react'
import emmers from '../assets/faq_emmers.png'

export const FAQ = {
  questions: [
    {
      q: "Hoe moet er gemeten worden?",
      a: (
        <div>
          <p>
            De "keuken" van de afdeling waar gemeten wordt is bij uitstek de
            locatie waar de totale meting van de verspilling gemeten kan worden.
            In de keuken wordt de “overgebleven” en niet-geconsumeerde voeding
            verzameld en vervolgens gewogen met de weegschaal. Om een juiste
            meting te verkrijgen moeten we de etensresten gescheiden verzamelen.
            Hoe preciezer we het voedsel scheiden, des te preciezer kunnen we de
            waarde van de verspilling berekenen. We realiseren ons dat dit niet
            altijd mogelijk is. Er is daarom ook een categorie “Alles bij
            elkaar”. We onderscheiden de volgende categorieën verspilling:
          </p>
          <ul>
            <li>Soep </li>
            <li>Vlees/vis/veg (zo weinig mogelijk jus) </li>
            <li>Jus/saus</li>
            <li>Vlees/vis/veg in saus</li>
            <li>Zetmeel </li>
            <li>Groente</li>
            <li>Samengestelde component (bijvoorbeeld stamppot)</li>
            <li>Eenpansgerecht</li>
            <li>Dessert </li>
            <li>Alles bij elkaar</li>
          </ul>
          <p>De componenten worden aan het einde van de maaltijd per component gewogen en geregistreerd.</p>
          <ul>
            <li>Plaats emmers (per component) op een kar</li>
            <img src={emmers} alt=""/>
            <li>Verdeel de resten over de emmers.</li>
        	  <li>Weeg voor de zekerheid even wat een lege emmer weegt en noteer dat.</li>
            <li>Op de meeste weegschalen kan je eerst de lege emmer zetten en dan op tarra klikken. Dan wordt het gewicht van de emmer niet meegenomen in de meting. Als de emmer op de weegschaal staat, geeft de weegschaal 0 gram weer. </li>
            <li>Weeg de inhoud van de emmers en noteer deze. </li>
            <li>Voer de gemeten gegevens in in de app.</li>
          </ul>
        </div>
      ),
    },
    {
      q: "Hoe moet ik een dervingsmeting invullen in de app?",
      a: 
        <div>
          <ul>
            <li>Na inloggen kies je voor “Dervingsregistratie” </li>
            <li>Je kiest de afdeling waarvoor je de gegevens invult</li>
            <li>Je vult je naam in (voor- en achternaam)</li>
            <li>Je vult de datum van de meting in, klik hiervoor op de kalender rechts in het invoerveld</li>
            <li>Je vult het aantal personen in dat heeft meegegeten op de dag van de meting</li>
            <li>Klik op “Doorgaan”</li>
            <li>Kies de eerste gerecht categorie die je gemeten hebt op deze dag</li>
            <li>Kies de reden van de derving. Als de exacte reden er niet bij staat, vul toch in wat het dichtst in de buurt komt. Je kan bij het invullen van de grammen nog een extra opmerking toevoegen</li>
            <li>Vul het gewicht in van de derving in grammen (hoeveel gram wordt er weggegooid)</li>
            <li>Kies “Doorgaan”</li>
            <li>Nu krijg je een overzicht van de ingevulde gegevens</li>
            <li>Nu kan je kiezen: <br/>
              1) of je voor een andere maaltijdcomponent voor dezelfde dag derving in wilt voeren (“ nieuwe maaltijdcomponent”) <br/>
              2) of je voor een andere dag derving in wilt vullen (“Nieuwe meting”) <br/>
              3) of je de ingevulde gegevens wilt verzenden (“Verzenden”)<br/>
              4) of je de ingevulde gegevens wilt aanpassen  <br/>
            </li>
          </ul>
        </div>
    },
    {
      q: "Is het beter om “alles bij elkaar” te wegen of per component?",
      a: 
        <div>
          <p>allebei is goed maar hoe meer specifiek de meting is, hoe beter de waarde van de derving bepaald kan worden</p>
        </div>
    },
    {
      q: "Waarom een week derving meten?",
      a: 
        <div>
          <p>omdat niet alle dagen hetzelfde zijn in de zorg, is het beter om 1 week te meten. Zo krijg je een representatiever beeld van de derving</p>
        </div>
    },
    {
      q: "Wat moet gemeten worden?",
      a: 
        <div>
          <p>Alleen de onderdelen die voor consumptie geschikt zijn, dus geen schillen, botten, graten, koffiedik, kaaskorsten etc. Wel producten die over tht zijn, beschimmeld fruit of broodresten.</p>
        </div>
    },
    {
      q: "Ik ben vandaag de meting vergeten te doen, wat nu?",
      a: 
        <div>
          <p>Overleg of er een extra dag gemeten kan worden aan het einde van de afgesproken periode. Probeer wel alle weekdagen te meten dus als je een donderdag hebt gemist, meet dan een andere donderdag. </p>
        </div>
    },
  ],
};
