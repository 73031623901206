import request from "../helpers/request";
import { AuthHeader } from "../helpers";
export { getDepartments };

async function getDepartments() {
  return request({
    url: "departments/",
    method: "GET",
    headers: AuthHeader(),
  }).then((res) => {
    console.log(res);
    return res;
  });
}
