import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login, Form, FAQ } from "./pages";
import { getUserToken } from "./helpers";

import "./App.css";

function App() {
  const loadHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  // We listen to the resize event
  window.addEventListener("resize", () => {
    loadHeight();
  });

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    loadHeight();
    updateToken();
    setLoading(false);

    return function cleanup() {};
  }, []);

  const updateToken = () => {
    let userId = getUserToken();
    if (userId) setUser(userId);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    updateToken();
    window.location.href = "/";
  };

  if (loading === true) {
    return <div></div>;
  }

  return (
    <div style={{ height: "100%" }}>
      <Router>
        {user == null && (
          <Switch>
            <Route path="/Login">
              <Login updateToken={updateToken} />
            </Route>
            <Route exact path="/">
              <Login updateToken={updateToken} />
            </Route>
          </Switch>
        )}
        {user && (
          <Switch>
            <Route exact path="/">
              <Form />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/logout">{handleLogout}</Route>
          </Switch>
        )}
      </Router>
    </div>
  );
}

export default App;
