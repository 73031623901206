import React from "react";

const GridElement = (props) => {
  return (
    <div className="grid__element" onClick={() => props.onClick()}>
      <span style={{ fontSize: "1rem" }}>{props.title}</span>
    </div>
  );
};

export default GridElement;
