import request from "../helpers/request";
import { AuthHeader } from "../helpers";
export { getCategories, getWasteTypes };

async function getCategories() {
  return request({
    url: "meal-categories/",
    method: "GET",
    headers: AuthHeader(),
  }).then((res) => {
    return res;
  });
}

async function getWasteTypes() {
  return request({
    url: "waste-types/",
    method: "GET",
    headers: AuthHeader(),
  }).then((res) => {
    return res;
  });
}
