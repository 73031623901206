import React from "react";
import {Link} from 'react-router-dom';

const Header = (props) => {

  const toggleSidebar = () => {
    const sidebar = document.querySelector(".sidebar")
    sidebar.classList.toggle('showSidebar')
  }

  return (
    <header>
      <nav className="navbar">
        <ul className="navbar__list">
          <li
            style={{ width: "10vw" }}
            onClick={() => props.previous()}
            className="navbar__list__item"
          >
            {/* Only show back button if current is not home*/}
            {props.formStep > 0 && <i className="fas fa-chevron-left"></i>}
          </li>
          <li className="navbar__list__item">{props.headerText}</li>
          <li style={{ width: "10vw" }} className="navbar__list__item">

            <i className="fas fa-bars" onClick={toggleSidebar}></i>
          </li>
        </ul>
      </nav>
      <div className="sidebar">
        <ul className="sidebar__list">
          <li><Link to="/"><i className="fas fa-home"></i> Home</Link></li>
          <li><Link to="/faq"><i className="fas fa-question-circle"></i> Veelgestelde vragen</Link></li>
          <li className="sidebar__list__logout"><Link to="/logout"><i className="fas fa-arrow-right"></i> Uitloggen</Link></li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
