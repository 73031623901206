import React from "react";
import { Spacer } from "../components";

const Home = (props) => {
  return (
    <div className="container__form">
      <h3>Welkom bij de dervingregistratietool!</h3>
      <br />
      <p className="textBlock">
        Je gaat nu beginnen met de dervingsregistratie. Zorg dat je de
        weegschaal bij de hand hebt en de opgetreden derving kan scheiden. Volg
        het eenvoudige stappenproces door op de onderstaande knop te drukken
      </p>
      <br />
      <b>Samen maken we het verschil!</b>
      <Spacer />
      <button onClick={() => props.nextStep()} className="button">
        Starten
      </button>
    </div>
  );
};

export default Home;
