import React, { useEffect, useState } from "react";
import { getCategories, getWasteTypes } from "../services";
import { GridElement, Spacer, ModalComponent } from "../components";

const Category = (props) => {
  useEffect(() => {
    loadCategories();
  }, []);

  async function loadCategories() {
    let categories = await getCategories();
    let wasteTypes = await getWasteTypes();
    if (categories) setCategories(categories);
    if (wasteTypes) setWasteTypes(wasteTypes);
  }

  const openModal = (cat) => {
    props.setMealCategory(cat);
    setModal(!modal);
  };

  const toggleModal = () => {
    if (modal === "none") setModal("block");
    else setModal("none");
  };

  // Waste type buttons
  const renderWasteTypeButton = (data) => {
    let className = "wasteTypeButton";
    if (props.wasteType !== undefined) {
      if (props.wasteType.id === data.id) className = "wasteTypeButton__active";
      else className = "wasteTypeButton";
    }
    return (
      <button
        key={data.id}
        className={className}
        onClick={() => {
          props.setWasteType(data);
        }}
      >
        {data.name}
      </button>
    );
  };

  const [categories, setCategories] = useState();
  const [wasteTypes, setWasteTypes] = useState();
  const [modal, setModal] = useState("none");

  if (categories) {
    return (
      <div className="container__filler">
        <span style={{ margin: "10px" }}>Categorie selecteren</span>
        <br />
        <br />
        <div className="grid">
          {categories.meal_categories.map((cat, i) => {
            return (
              <GridElement
                title={cat.name}
                onClick={() => openModal(cat)}
                key={cat.id}
              />
            );
          })}
        </div>
        {props.mealCategory && (
          <ModalComponent
            toggle={toggleModal}
            visible={modal}
            title={props.mealCategory.name}
          >
            <span>Reden van derving</span>
            {wasteTypes && (
              <div className="modalButtonContainer">
                {wasteTypes.waste_types.map((wtype, i) => {
                  return renderWasteTypeButton(wtype);
                })}
              </div>
            )}
            <Spacer />
            {props.wasteType && (
              <button
                data-testid="catNextPage"
                onClick={() => props.nextStep()}
                className="button"
              >
                Doorgaan
              </button>
            )}
            <Spacer />
          </ModalComponent>
        )}
      </div>
    );
  }
  return null;
};

export default Category;
