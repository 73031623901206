import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Header } from "../components";
import wastewijzer_logo from '../assets/wastewijzer_logo.png'
import { login } from "../services";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [refresh, setRefresh] = useState();

  const handleClick = () => {
    sendLogin();
  };

  async function sendLogin() {
    const token = await login(email, password);
    props.updateToken();
    setRefresh(true)
    return token;
  }

  return (
    <div style={{height: '100%'}}>
      <Header headerText="Aanmelden" />
      <div className="loginContainer">
        <input
          className="input"
          type="text"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="input"
          placeholder="Wachtwoord"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="button" onClick={() => handleClick()}>
          Login
        </button>
        <div className="login__background">
        <img src={wastewijzer_logo} alt=""/>
      </div>
        {refresh && <Redirect to="/" />}
        
      </div>

    </div>
  );
};

export default Login;
