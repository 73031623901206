export function AuthHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    return { "x-access-token": user };
  } else {
    return {};
  }
}
