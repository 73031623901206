import request from "../helpers/request";

export { login };

function login(email, password) {
  const auth = new Buffer(`${email}:${password}`).toString("base64");
  const authHeader = "Basic " + auth;
  return request({
    url: "users/login",
    method: "POST",
    headers: { Authorization: authHeader },
  }).then((res) => {
    if (res.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(res.token));
      return res.token;
    }
  });
}
